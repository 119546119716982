<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'item', 'disabled': loadingTable}" :tabindex="loadingTable ? -1 : ''" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#item" @click="!loadingTable && (tab = 'item', resetFilter('item'))">ITEM</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'desain', 'disabled': loadingTable}" :tabindex="loadingTable ? -1 : ''" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#desain" @click="!loadingTable && (tab = 'desain', resetFilter('desain'))">DESAIN</a>
                </li>
            </ul>
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter align-items-center position-relative fs-6" v-if="tab == 'item'">
                        <div class="filter-item">
                            <span>Warehouse</span>
                            <el-select v-model="filter.warehouse_id" @change="onFilter('item')" placeholder="Warehouse" style="width: 200px;">
                                <el-option v-for="(o, i) in listWarehouse" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>  
                        <div class="filter-item">
                            <span>Tipe</span>
                            <el-select v-model="filter.tipe_produk" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Tipe Produk" clearable style="width: 100px;">
                                <el-option v-for="(o, i) in ['MTO', 'RTW', 'DISPLAY']" :key="i" :value="o" :label="o" />
                            </el-select>
                        </div>     
                        <div class="filter-item">
                            <span>Brand</span>
                            <el-select v-model="filter.brand_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Brand" clearable style="width: 200px;">
                                <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>                
                        <div class="filter-item">
                            <span>SKU</span>
                            <el-input v-model="filter.sku" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Search SKU" clearable style="width: 150px;" />
                        </div>
                        <div class="filter-item">
                            <span>Artikel</span>
                            <el-input v-model="filter.artikel" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Search artikel" clearable style="width: 150px;"/>
                        </div>
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.raw_category_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="kategori" clearable style="width: 200px;" >
                                <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name" :disabled="o.id == 35"/>
                            </el-select>
                        </div> 
                        <div class="filter-item">
                            <span>WARNA</span>
                            <el-select v-model="filter.color_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Warna" clearable style="width: 200px;" >
                                <el-option v-for="(o, i) in listColor" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div> 

                        <div class="filter-item">
                            <span>Vendor</span>
                            <el-select v-model="filter.vendor_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Vendor" clearable style="width: 200px;">
                                <el-option v-for="(o, i) in listVendor" :key="i" :value="o.id" :label="o.nama_vendor" />
                            </el-select>
                        </div>     
                        <div class="filter-item d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('item'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('item'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="filter d-flex align-items-center position-relative fs-6" v-if="tab == 'desain'">
                        <div class="filter-item">
                            <span>Warehouse</span>
                            <el-select v-model="filter.warehouse_id" @change="onFilter('item')" placeholder="Warehouse" style="width: 200px;">
                                <el-option v-for="(o, i) in listWarehouse" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input @change="onFilter('desain')" @clear="onFilter('desain')" v-model="filter.desain_id" placeholder="Search Desain ID" clearable />
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" @change="onFilter('desain')" @clear="onFilter('desain')" placeholder="Pilih Folder" clearable>
                                <el-option v-for="(o, i) in listRawFolder" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>  
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('desain'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('desain'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary" @click="exportExcel()" :data-kt-indicator="loadingSubmit ? 'on' : 'off'">
                            <span class="indicator-label">
                                <div class="d-flex">
                                    <span class="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                            <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                            <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                        </svg>
                                    </span>
                                    <span>Export</span>
                                </div>
                            </span>
                            <span class="indicator-progress">
                              Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body tab-content bg-white" id="tab_raw_product">
            <div class="tab-pane fade" :class="tab == 'item' ? 'show active' : ''" id="item" role="tabpanel" v-if="tab == 'item'">
                <div ref="tableRef" class="table-fixed-header">
                    <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                                <th class="w-50px">#</th>
                                <th style="min-width: 150px;">Warehouse</th>
                                <th>Brand</th>
                                <th>Kategori</th>
                                <th>Warna</th>
                                <th>Ukuran</th>
                                <th>Artikel</th>
                                <th>SKU</th>
                                <th>Vendor</th>
                                <th class="text-end">Qty</th>
                                <th class="text-end">Harga</th>
                                <th class="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(item, i) in tableData" :key="i">
                                <td class="w-50px">{{ item.index }}</td>
                                <td>{{ item.warehouse }}</td>
                                <td>{{ item.brand_name }}</td>
                                <td>{{ item.raw_category }}</td>
                                <td>{{ item.color }}</td>
                                <td>{{ item.size }}</td>
                                <td>{{ item.artikel }}</td>
                                <td>{{ item.sku }}</td>
                                <td>{{ item.vendor }}</td>
                                <td class="text-end">{{ formatIDR(item.qty) }}</td>
                                <td class="text-end">{{ formatIDR(item.price) }}</td>
                                <td class="text-end">{{ formatIDR(item.qty * item.price) }}</td>
                            </tr>
                        </tbody>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" :class="tab == 'desain' ? 'show active' : ''" id="desain" role="tabpanel" v-if="tab == 'desain'">
                <div ref="tableRef" class="table-fixed-header">
                    <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                                <th class="w-50px">#</th>
                                <th>Gambar</th>
                                <th style="min-width: 150px;">Warehouse</th>
                                <th>Desain ID</th>
                                <th>Print Size</th>
                                <th>Description</th>
                                <th class="text-end">Qty</th>
                                <th class="text-end">Harga</th>
                                <th class="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(item, i) in tableData" :key="i">
                                <td class="w-50px">{{ item.index }}</td>
                                <td>
                                   <img :src="img_url_desain+item.gambar" class="tbl-image" /> 
                                </td>
                                <td>{{ item.warehouse }}</td>
                                <td>{{ item.desain_id }}</td>
                                <td>{{ item.print_size }}</td>
                                <td>{{ item.print_size_detail || '-' }}</td>
                                <td class="text-end">{{ formatIDR(item.qty) }}</td>
                                <td class="text-end">{{ formatIDR(item.price) }}</td>
                                <td class="text-end">{{ formatIDR(item.qty * item.price) }}</td>
                            </tr>
                        </tbody>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </table>
                </div>
            </div>
        </div>

        <div class="text-center my-5">
            <el-pagination 
                background 
                :pager-count="4"
                layout="prev, pager, next" 
                :page-count="pagination.total_page" 
                @next-click="handlePageChange" 
                @prev-click="handlePageChange" 
                @current-change="handlePageChange" 
                @update:current-page="pagination.page"
            />
        </div>

    </div>

    <div class="summary">
      <div class="summary-list-fixed">
        <div class="fs-4 fw-bolder text-gray-700">
          <div class="d-flex">
            <span class="text-uppercase">Toko</span>
            <span class="ms-auto">{{ tableData.length > 0 ? tableData[0].warehouse : '-' }}</span>
          </div>
        </div>
        <div class="fs-4 fw-bolder text-gray-700">
          <div class="d-flex">
            <span class="text-uppercase">Total Produk</span>
            <span class="ms-auto">{{ summary.total_item }}</span>
          </div>
        </div>
        <div class="fs-4 fw-bolder text-gray-700">
          <div class="d-flex">
            <span class="text-uppercase">Qty</span>
            <span class="ms-auto">{{ summary.total_qty }}</span>
          </div>
        </div>
        <div class="fs-4 fw-bolder text-gray-700">
          <div class="d-flex">
            <span class="text-uppercase">Total</span>
            <span class="ms-auto">{{ formatIDR(summary.total, { prefix: 'Rp'}) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_1" v-if="tab == 'item'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_1_header_1">
          <button
            class="accordion-button fs-4 fw-bolder text-gray-700 bg-white text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_1_body_1"
            aria-expanded="true"
            aria-controls="kt_accordion_1_body_1"
          >
            Brand
          </button>
        </h2>
        <div
          id="kt_accordion_1_body_1"
          class="accordion-collapse collapse"
          aria-labelledby="kt_accordion_1_header_1"
          data-bs-parent="#kt_accordion_1"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.brand" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.brand_name }}</span>
                                <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                            </div>
                            <div class="d-flex text-gray-400">
                                <span>Stok</span>
                                <span class="ms-auto">{{ o.qty }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_2" v-if="tab == 'item'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_2_header_2">
          <button
            class="accordion-button fs-4 fw-bolder text-gray-700 bg-white text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_2_body_2"
            aria-expanded="true"
            aria-controls="kt_accordion_2_body_2"
          >
            Kategori
          </button>
        </h2>
        <div
          id="kt_accordion_2_body_2"
          class="accordion-collapse collapse"
          aria-labelledby="kt_accordion_2_header_2"
          data-bs-parent="#kt_accordion_2"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.category" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.category }}</span>
                                <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                            </div>
                            <div class="d-flex text-gray-400">
                                <span>Stok</span>
                                <span class="ms-auto">{{ o.qty }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_6" v-if="tab == 'item'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_6_header_6">
          <button
            class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_6_body_6"
            aria-expanded="true"
            aria-controls="kt_accordion_6_body_6"
          >
            Warna
          </button>
        </h2>
        <div
          id="kt_accordion_6_body_6"
          class="accordion-collapse collapse"
          aria-labelledby="kt_accordion_6_header_6"
          data-bs-parent="#kt_accordion_6"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.color" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.color }}</span>
                                <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                            </div>
                            <div class="py-2">
                                <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                    <li>
                                        <div class="d-flex justify-content-between">
                                            <div>{{ item.category }}</div>
                                            <div>{{ item.qty }}</div>
                                        </div>
                                    </li>
                                </ul>
                                <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailColor(o)">Lihat detail</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_7" v-if="tab == 'item'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_7_header_7">
          <button
            class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_7_body_7"
            aria-expanded="true"
            aria-controls="kt_accordion_7_body_7"
          >
            Ukuran
          </button>
        </h2>
        <div
          id="kt_accordion_7_body_7"
          class="accordion-collapse collapse"
          aria-labelledby="kt_accordion_7_header_7"
          data-bs-parent="#kt_accordion_7"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.size" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.size }}</span>
                                <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                            </div>
                            <div class="py-2">
                                <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                    <li>
                                        <div class="d-flex justify-content-between">
                                            <div>{{ item.category }}</div>
                                            <div>{{ item.qty }}</div>
                                        </div>
                                    </li>
                                </ul>
                                <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailSize(o)">Lihat detail</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_3" v-if="tab == 'desain'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_3_header_3">
          <button
            class="accordion-button fs-4 fw-bolder text-gray-700 bg-white text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_3_body_3"
            aria-expanded="true"
            aria-controls="kt_accordion_3_body_3"
          >
            Ukuran Print
          </button>
        </h2>
        <div
          id="kt_accordion_3_body_3"
          class="accordion-collapse collapse show"
          aria-labelledby="kt_accordion_3_header_3"
          data-bs-parent="#kt_accordion_3"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.print_size" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.print_size_name }}</span>
                                <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                            </div>
                            <div class="d-flex text-gray-400">
                                <span>Stok</span>
                                <span class="ms-auto">{{ o.qty }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <!--begin::Accordion-->
    <div class="accordion mb-4" id="kt_accordion_4" v-if="tab == 'desain'">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_4_header_4">
          <button
            class="accordion-button fs-4 fw-bolder text-gray-700 bg-whitebg-white ext-gray-600 text-hover-primary text-uppercase"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_4_body_4"
            aria-expanded="true"
            aria-controls="kt_accordion_4_body_4"
          >
            Folder
          </button>
        </h2>
        <div
          id="kt_accordion_4_body_4"
          class="accordion-collapse collapse hide"
          aria-labelledby="kt_accordion_4_header_4"
          data-bs-parent="#kt_accordion_4"
        >
            <div class="accordion-body p-0">
                <div class="summary m-0">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.folder" :key="i">
                            <div class="d-flex">
                                <span class="text-uppercase">{{ o.folder_name }}</span>
                                <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                            </div>
                            <div class="d-flex text-gray-400">
                                <span>Stok</span>
                                <span class="ms-auto">{{ o.qty }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->

    <el-dialog v-model="modalColor" :title="tempData.color.color + ' (' + tempData.color.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.color">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.color.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

    <el-dialog v-model="modalSize" :title="tempData.size.size + ' (' + tempData.size.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.size">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.size.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

</template>

<style scoped lang="scss">
    .accordion {
        .accordion-item {
            //border: none !important;

            button {
                padding: 1.5rem 2rem;
                border-radius: 0 !important;
            }
        }
    }
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore()
        
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const loadingSubmit = ref(false)
        const loadingTable = ref(false);
        const tableRef = ref(null)

        const modalColor = ref(false)
        const modalSize= ref(false)
        const tab = ref('item')

        const filterActive = ref(false)
        const filter = reactive({
            warehouse_id: 1,
            brand_id: null,
            category_id: null,
            artikel: null,
            sku: null,
            tipe_produk: null,
            folder_id: null,
            desain_id: null,
        })

        const summary = ref({
          total_item: 0,
          total_qty: 0,
          total: 0,
          brand: [],
          artikel: [],
          category: [],
          folder: [],
          print_size: [],
        })

        const formRef = ref(null);
        const tempData = reactive(Yup.object().shape({
            warehouse_id: Yup.number().required().label("Toko"),
            date: null,
            color: null,
        }))

        const tableData = reactive([]);

        const listBrand = computed(() => store.getters.getListBrand)
        const listCategory = computed(() => store.getters.getListCategory)
        const listColor = computed(() => store.getters.getListColor)
        const listVendor = computed(() => store.getters.getListVendor)
        const listWarehouse = computed(() => store.getters.getListWarehouse)
        const listRawFolder = computed(() => store.getters.getListRawFolder)

        const onFilter = async (type) => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            store.dispatch(Actions.ADD_FILTER, filter);
            filterActive.value = false


            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
            Object.keys(filter).map(key => filter[key] = null);

            filter.warehouse_id = 1;

            loadingTable.value = true

            filterActive.value = false

            await getData()

            loadingTable.value = false
        }

        // PAGINATION
        const currentPage = ref(1)

        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            loadingTable.value = true
            try {
                await tableData.splice(0)

                let endpoint = tab.value == 'item' ? 'report_stok_item' : 'report_stok_desain';
                let warehouse = filter.warehouse_id ? '&warehouse=' + filter.warehouse_id : '';
                let tipe_produk = filter.tipe_produk ? '&tipe_produk=' + filter.tipe_produk : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let color = filter.color_id ? '&color=' + filter.color_id : '';
                let vendor = filter.vendor_id ? '&vendor=' + filter.vendor_id : '';
                let artikel = filter.artikel ? '&artikel=' + filter.artikel : '';
                let sku = filter.sku ? '&sku=' + filter.sku : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';
                page = page ? '?page=' + page : '?page=1';
                
                await ApiService.get(endpoint + page + warehouse + tipe_produk + brand + category + color + vendor + artikel + sku + folder + desain_id)
                .then(async(response) => {
                    currentPage.value = page
                    pagination.value = await response.data.data.pagination
                    await Object.assign(tableData, response.data.data.data)

                    getSummary()
                })
                .catch((error) => {
                    console.log('Error getting sales report');
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loadingTable.value = false
        }

        const getSummary = async () => {
            try {
                let endpoint = tab.value == 'item' ? 'report_summary_stok_item' : 'report_summary_stok_desain';
                let warehouse_id = filter.warehouse_id ? '?warehouse_id=' + filter.warehouse_id : '';
                let tipe_produk = filter.tipe_produk ? '&tipe_produk=' + filter.tipe_produk : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let color = filter.color_id ? '&color=' + filter.color_id : '';
                let vendor = filter.vendor_id ? '&vendor=' + filter.vendor_id : '';
                let artikel = filter.artikel ? '&artikel=' + filter.artikel : '';
                let sku = filter.sku ? '&sku=' + filter.sku : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';

                await ApiService.get(endpoint + warehouse_id + tipe_produk + brand + category + color + vendor + artikel + sku + folder + desain_id)
                .then(async({ data }) => {
                    summary.value.total_item = data.data.total_item
                    summary.value.total_qty = data.data.total_qty
                    summary.value.total = data.data.total

                    if(tab.value == 'item') {
                        summary.value.brand = data.data.brand
                        summary.value.category = data.data.category
                        summary.value.artikel = data.data.artikel
                        summary.value.color = data.data.color
                        summary.value.size = data.data.size
                    } else {
                        summary.value.folder = data.data.folder
                        summary.value.print_size = data.data.print_size
                    }
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const getDetailColor = (data) => {
            try {
                tempData.color = data
                modalColor.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const getDetailSize = (data) => {
            try {
                tempData.size = data
                modalSize.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const exportExcel = async() => {
            if(!filter.warehouse_id) {
                Swal.fire({
                    text: "Gudang Wajib Dipilih",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                return;
            }

            if(!filter.brand_id) {
                Swal.fire({
                    text: "Brand Wajib Dipilih",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                return;
            }

            if(!filter.tipe_produk) {
                Swal.fire({
                    text: "Tipe Produk Wajib Dipilih",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                return;
            }

            let tipe_produk = filter.tipe_produk ? '&tipe_produk=' + filter.tipe_produk : '';
            let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
            let category = filter.category_id ? '&category=' + filter.category_id : '';
            let color = filter.color_id ? '&color=' + filter.color_id : '';
            let vendor = filter.vendor_id ? '&vendor=' + filter.vendor_id : '';
            let artikel = filter.artikel ? '&artikel=' + filter.artikel : '';
            let sku = filter.sku ? '&sku=' + filter.sku : '';
            let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
            let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';

            loadingSubmit.value = true

            await ApiService.get('export_stok_item/'+ filter.warehouse_id + '?' + tipe_produk + brand + category + color + vendor + artikel)
                .then(async(response) => {
                    
                    let getLink = response.data.data.link
                    let getFileName = response.data.data.fileName

                    window.open(getLink).focus();
                })
                .catch((error) => {
                    if(error.response) {
                        Swal.fire({
                            text: error.response.data.messages,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

            loadingSubmit.value = false
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs("Laporan Stok", ['Laporan Stok']);

            // Default store
            filter.warehouse_id = 1;

            store.dispatch(Actions.LIST_BRAND);
            store.dispatch(Actions.LIST_CATEGORY);
            store.dispatch(Actions.LIST_COLOR);
            store.dispatch(Actions.LIST_VENDOR);
            store.dispatch(Actions.LIST_WAREHOUSE);
            store.dispatch(Actions.LIST_RAW_FOLDER);

            getData()
        })

        return {
            formatIDR, moment,
            tab, modalColor, modalSize,
            loadingSubmit, loadingTable, 
            tableRef, img_url_mockup, img_url_desain,
            onFilter, resetFilter, filter, filterActive,
            listWarehouse, listBrand, listCategory, listColor, listVendor, listRawFolder,
            tempData, summary, getDetailColor, getDetailSize,
            tableData, exportExcel,
            handlePageChange, currentPage, pagination,
        }
    }
})
</script>